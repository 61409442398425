import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { constantCase } from 'constant-case'

export default function useFirebaseError({ wrapper }) {
  const [error, setError] = useState(null)

  return useMemo(
    () => ({
      el: error
        ? React.cloneElement(
            wrapper,
            {},
            <FormattedMessage
              defaultMessage="{code}: {message}"
              values={{
                cc_code: constantCase(error.code),
                code: error.code,
                message: error.message
              }}
            />
          )
        : null,
      code: error?.code,
      message: error?.message,
      clear: () => setError(null),
      set: setError
    }),
    [error, wrapper]
  )
}
