import {
  Avatar,
  Button,
  FormHelperText,
  Paper,
  Typography
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/styles'
import { useFirebase } from '@sb-konzept/firebase-hooks'
import Alert, { useAlert } from 'components/Alert'
import Box from 'components/Box'
import { FastField as Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import useFirebaseError from 'hooks/useFirebaseError'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import config from 'config'
import Router from 'next/router'
import callApi from 'utils/callApi'
import Link from 'components/Link'

const useStyles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    height: '90%',
    alignItems: 'center',
    display: 'flex', // Fix IE 11 issue.
    maxWidth: 400,
    margin: '0 auto'
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    // [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
    //   width: 400,
    //   marginLeft: 'auto',
    //   marginRight: 'auto'
    // }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  }
}))

function Logo() {
  return <Box component="img" src={config.logo} width="70%" />
}

export default function Login() {
  const [alert, alertProps] = useAlert({ id: 'reset-password' })
  const firebase = useFirebase()
  const classes = useStyles()
  const loginError = useFirebaseError({ wrapper: <FormHelperText error /> })
  const handleSubmit = useCallback(
    async (values, { setFieldError, setSubmitting }) => {
      const { email, password } = values
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
        Router.push('/dashboard')
      } catch (error) {
        loginError.set(error)
        if (error.code === 'auth/user-not-found') {
          setFieldError('email', true)
        } else if (error.code === 'auth/wrong-password') {
          setFieldError('password', true)
        }
      } finally {
        setSubmitting(false)
      }
    },
    [firebase, loginError]
  )

  return (
    <div className={classes.main}>
      <Box alignItems="center" display="flex" flexDirection="column">
        <Paper className={classes.paper}>
          <Logo />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <FormattedMessage defaultMessage="Sign in" />
          </Typography>

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              <TextField
                fullWidth
                required
                autoComplete="email"
                label={<FormattedMessage defaultMessage="E-Mail" />}
                margin="normal"
                name="email"
                type="email"
              />

              <TextField
                fullWidth
                required
                autoComplete="current-password"
                label={<FormattedMessage defaultMessage="Password" />}
                margin="normal"
                name="password"
                type="password"
              />
              {loginError.el}
              <Field>
                {({ form }) => (
                  <>
                    <Button
                      fullWidth
                      className={classes.submit}
                      color="primary"
                      disabled={!form.dirty || form.isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      <FormattedMessage defaultMessage="signIn" />
                    </Button>

                    {form.errors.password ? (
                      <Button
                        fullWidth
                        color="primary"
                        disabled={!form.dirty || form.isSubmitting}
                        onClick={async () => {
                          form.setSubmitting(true)
                          try {
                            await callApi('reset-password', form.values)
                            form.setFieldError('password', false)
                            loginError.clear()
                            await alert.show()
                          } catch (e) {
                            loginError.set(e)
                          }
                          form.setSubmitting(false)
                        }}
                      >
                        <FormattedMessage defaultMessage="Forgot Password?" />
                      </Button>
                    ) : null}
                  </>
                )}
              </Field>
            </Form>
          </Formik>
        </Paper>
        <Box mt={2}>
          <Typography color="textSecondary">
            Powered by BabelBing 2020
          </Typography>
        </Box>
      </Box>
      <Box
        bgcolor="grey.600"
        bottom={0}
        color="common.white"
        display="flex"
        justifyContent="space-between"
        left={0}
        p={1}
        position="absolute"
        width="100%"
      >
        <Typography color="inherit">
          BabelBing CRM - all rights reserved by SB Konzept GmbH 2020
        </Typography>
        <Typography color="inherit">
          <Link color="inherit" href="/legal/imprint">
            Impressum
          </Link>
          &nbsp; &nbsp;
          <Link color="inherit" href="/legal/terms">
            AGB
          </Link>
          &nbsp; &nbsp;
          <Link color="inherit" href="/legal/data-policy">
            Datenschutz
          </Link>
        </Typography>
      </Box>
      <Alert
        {...alertProps}
        closeButton={<FormattedMessage defaultMessage="Ok!" />}
        description={
          <FormattedMessage defaultMessage="You can reset your password by using the link we just sent to you!" />
        }
        title={
          <FormattedMessage defaultMessage="We sent a password reset email." />
        }
      />
    </div>
  )
}

Login.redirect = ({ user }) => (user ? '/dashboard' : null)
