import fetch from 'isomorphic-fetch'
import config from 'config'
import { serializer } from 'utils/isomorphic-firebase'
import { memoizeWith, complement, anyPass, isEmpty, isNil } from 'ramda'

export class CallApiError extends Error {
  name = 'CallApiError'

  async extendWithResponse(response) {
    this.code = response.status
    this.statusText = response.statusText
    try {
      this.data = await response.json()
    } catch (e) {}
  }
}

const getHasBody = complement(anyPass([isEmpty, isNil]))

function getUrl(name, req) {
  if (process.env.FUNCTION_TARGET) {
    return `${config.baseUrl}/api/${name}`
  }
  if (req) {
    return `http://localhost:${req.socket.localPort}/api/${name}`
  }
  return `/api/${name}`
}

export default async function callApi(name, body, req) {
  const { _text: text, _headers = {}, _method, ...payload } = body || {}
  const hasBody = getHasBody(payload)
  const url = getUrl(name, req)
  const method = _method || (hasBody ? 'POST' : 'GET')
  const response = await fetch(url, {
    method,
    headers: {
      ...(hasBody && { 'Content-Type': 'application/json' }),
      ...(req && { cookie: req.headers.cookie }),
      ..._headers
    },
    body: hasBody ? JSON.stringify(serializer.serialize(payload)) : undefined
  })
  if (response.status === 204) {
    return null
  }

  if (response.status < 400) {
    const result = text ? await response.text() : await response.json()
    return serializer.deserialize(result)
  }

  const cloned = response.clone()
  const error = new CallApiError(await response.text())
  await error.extendWithResponse(cloned)
  throw error
}

export const memoizedCallApi = memoizeWith(
  (endpoint, body, secs) =>
    JSON.stringify({
      endpoint,
      body,
      memoFor: Math.floor(Date.now() / (secs * 1000))
    }),
  (endpoint, body) => {
    if (typeof window === 'undefined')
      throw new Error('memoizedCallApi is not supported on the server')
    return callApi(endpoint, body)
  }
)
