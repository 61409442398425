import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React, { useMemo, useRef, useState } from 'react'

function useDeferred() {
  const deferred = useRef({})
  function defer() {
    deferred.current.promise = new Promise((res, rej) => {
      deferred.current.resolve = res
      deferred.current.reject = rej
    })
  }
  return [deferred, defer]
}

export function useAlert({ id }) {
  const [open, setOpen] = useState(false)
  const [showing, show] = useDeferred()
  const alertProps = useMemo(
    () => ({
      id,
      open,
      handleClose: () => {
        setOpen(false)
        showing.current.resolve(true)
      }
    }),
    [id, open] // eslint-disable-line
  )
  const alert = useMemo(
    () => ({
      show: () => {
        show()
        setOpen(true)
        return showing.current.promise
      }
    }),
    [] // eslint-disable-line
  )

  return [alert, alertProps]
}

export default function Alert(props) {
  const {
    open,
    handleClose,
    handleReject,
    title,
    description,
    id,
    closeButton
  } = props
  return (
    <Dialog
      aria-describedby={`${id}-description`}
      aria-labelledby={`${id}-title`}
      open={open}
      onClose={handleReject}
    >
      <DialogTitle id={`${id}-title`}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${id}-description`}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={handleClose}>
          {closeButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
